import React,{useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from './OpenHeader';
import Footer from '../Components/Footer';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Spinner from '../Components/Spinner';
import {domain} from '../domain';
const defaultTheme = createTheme();
axios.defaults.withCredentials = true;
export default function SignIn() {

  const navigate = useNavigate()

  const [Backdropopen, setBackdropopen] = useState(false);

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message:""
  });
  const { vertical, horizontal, open, message } = state;

  const handleClose = () => {
    setState({ ...state, open: false,message:"" });
  };

  function logout(){
    setBackdropopen(true)
    axios.get(`${domain}api/logout`).then((data)=>{
        if (data.data.Auth){
            navigate("/orders")
        }
        else{
            navigate("/login")
        }
        setBackdropopen(false)
    }).catch(()=>{
      setBackdropopen(false)
      setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    })
  }

  const handleSubmit = (event) => {
    setBackdropopen(true)
    event.preventDefault();
    let data = new FormData(event.currentTarget);
    if (data.get('password') == ""  || data.get('password') == undefined){
      setState({ vertical: 'top',horizontal: 'center', open: true,message:"Password Can't be Empty" });
      setBackdropopen(false)
      return
    }
    axios.post(`${domain}api/change`,{"password":data.get('password')}).then((data)=>{
        setBackdropopen(false)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:data.data.Message });
        logout()
    }).catch(()=>{
      setBackdropopen(false)
      setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    })
  };

  return (
    <ThemeProvider theme={defaultTheme}>
        <Header />
        <Spinner open={Backdropopen} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={message}
          key={vertical + horizontal}
        />
      <Container style={{marginTop:"5%"}} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <ChangeCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="current-password"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}