import React,{useEffect,useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import {domain} from '../domain';
import Snackbar from '@mui/material/Snackbar';
import Spinner from '../Components/Spinner';
import axios from 'axios';
axios.defaults.withCredentials = true;
const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  const [Backdropopen, setBackdropopen] = useState(false);

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message:""
  });
  const { vertical, horizontal, open, message } = state;

  const handleClose = () => {
    setState({ ...state, open: false, message:"" });
  };

  const [Auth, setAuth] = useState(false);
  const [Role, setRole] = useState("");

  function logout(){
    setBackdropopen(true)
    axios.get(`${domain}api/logout`).then((data)=>{
        if (data.data.Auth){
            navigate("/orders")
        }
        else{
            navigate("/login")
        }
        setBackdropopen(false)
    }).catch(()=>{
        setBackdropopen(false)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
      })
  }

  useEffect(()=>{
    setBackdropopen(true)
    axios.get(`${domain}api/check`).then((data)=>{
      if (!data.data.Auth){
        setBackdropopen(false)
        navigate("/login")
    }
    else{
      setRole(data.data.Role)
      setBackdropopen(false)
      setAuth(true)
    }
  }).catch(()=>{
    setBackdropopen(false)
    setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    })
  },[])

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      <Link to="/" style={{color:"white",textDecoration:"none"}}>Pardouta</Link>
      </Typography>
      <Divider />
      <List>
        {!Auth?
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
            <Link to="/login" style={{color:"white",textDecoration:"none"}}><ListItemText primary={"Login"} /></Link>
            </ListItemButton>
          </ListItem>:
          <>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to="/orders" style={{color:"white",textDecoration:"none"}}><ListItemText primary={"Orders"} /></Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to="/sold" style={{color:"white",textDecoration:"none"}}><ListItemText primary={"RRR Orders"} /></Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to="/products" style={{color:"white",textDecoration:"none"}}><ListItemText primary={"Products"} /></Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to="/change" style={{color:"white",textDecoration:"none"}}><ListItemText primary={"Change"} /></Link>
            </ListItemButton>
          </ListItem>
          {Role=="Hurairah"?
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to="/import" style={{color:"white",textDecoration:"none"}}><ListItemText primary={"Import"} /></Link>
            </ListItemButton>
          </ListItem>
          :<></>}
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
            <Link onClick={()=>{logout()}} style={{color:"white",textDecoration:"none"}}><ListItemText primary={"Logout"} /></Link>
            </ListItemButton>
          </ListItem>
          </>}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
        <Spinner open={Backdropopen} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={message}
          key={vertical + horizontal}
        />
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <ThemeProvider theme={darkTheme}>
            <AppBar component="nav" style={{position:"relative"}}>
            <Toolbar>
                <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
                >
                <MenuIcon />
                </IconButton>
                <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                    <Link to="/" style={{color:"white",textDecoration:"none"}}>Pardouta</Link>
                </Typography>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {!Auth?<Button sx={{ color: '#fff' }}>
                    <Link to="/login" style={{color:"white",textDecoration:"none"}}>Login</Link>
                  </Button>:
                  <>
                  <Button sx={{ color: '#fff' }}>
                    <Link to="/orders" style={{color:"white",textDecoration:"none"}}>Orders</Link>
                  </Button>
                  <Button sx={{ color: '#fff' }}>
                    <Link to="/sold" style={{color:"white",textDecoration:"none"}}>RRR Orders</Link>
                  </Button>
                  <Button sx={{ color: '#fff' }}>
                    <Link to="/products" style={{color:"white",textDecoration:"none"}}>Products</Link>
                  </Button>
                  <Button sx={{ color: '#fff' }}>
                    <Link to="/change" style={{color:"white",textDecoration:"none"}}>Change</Link>
                  </Button>
                  {Role=="Hurairah"?
                  <Button sx={{ color: '#fff' }}>
                    <Link to="/import" style={{color:"white",textDecoration:"none"}}>Import</Link>
                  </Button>
                  :<></>}
                  <Button sx={{ color: '#fff' }}>
                    <Link onClick={()=>{logout()}} style={{color:"white",textDecoration:"none"}}>Logout</Link>
                  </Button>
                  </>}
                </Box>
            </Toolbar>
            </AppBar>
        </ThemeProvider>
        <nav>
        <ThemeProvider theme={darkTheme}>
            <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            >
            {drawer}
            </Drawer>
            </ThemeProvider>
        </nav>
        </Box>
    </React.Fragment>
  );
}
export default DrawerAppBar;