import React,{useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FileUpload from '@mui/icons-material/FileUpload';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from './OpenHeader';
import Footer from '../Components/Footer';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Spinner from '../Components/Spinner';
import * as XLSX from 'xlsx';
import {domain} from '../domain'
const defaultTheme = createTheme();
axios.defaults.withCredentials = true;
function Import() {

    const navigate = useNavigate()

    const [Backdropopen, setBackdropopen] = useState(false);
  
    const [state, setState] = useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
      message:""
    });
    const { vertical, horizontal, open, message } = state;
  
    const handleClose = () => {
      setState({ ...state, open: false,message:"" });
    };
  
    const upload = (event) => {
        try{
            setBackdropopen(true)
            event.preventDefault();
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });

            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            axios.post(`${domain}api/upload`,{"Data":jsonData}).then((data)=>{
                setBackdropopen(false)
                setState({ vertical: 'top',horizontal: 'center', open: true,message:"Uploaded Successfully" });
              }).catch(()=>{
                setBackdropopen(false)
                setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
              })
            };

        reader.readAsArrayBuffer(file);
        }
        catch(e){
            setBackdropopen(false)
            setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
        }
    };


  return (
    <React.Fragment>
        <ThemeProvider theme={defaultTheme}>
            <Header />
            <Spinner open={Backdropopen} />
            <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message={message}
            key={vertical + horizontal}
            />
            <Container style={{marginTop:"5%"}} component="main" maxWidth="xs">
                <CssBaseline />
                    <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <FileUpload />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Upload Excel File
                    </Typography>
                    <Box noValidate sx={{ mt: 1 }}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="file"
                        onChange={(e)=>{upload(e)}}
                        label="Select Excel File..."
                        type="file"
                        id="file"
                        autoFocus
                        />
                    </Box>
                </Box>
            </Container>
            <Footer/>
        </ThemeProvider>
    </React.Fragment>
  )
}

export default Import