import React from 'react';
import '../App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
function Footer() {
    return ( 
        <React.Fragment>
            <Box style={{backgroundColor:"rgb(0, 0, 0)",bottom: "0",position: "fixed",width: "100%"}}>
                <Container component="main" maxWidth="md">
                <p className='footer-p' style={{color:"white", fontSize: "larger", marginTop: "3%",textAlign:"center"}}>
                    &copy; Copyright Pardouta, Design and Developed by Hurairah558
                </p>
                </Container>
            </Box>
        </React.Fragment>
     );
}

export default Footer;