import React,{useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function Spinner(props) {

    const [Backdropopen, setBackdropopen] = useState(false);

    const BackdropClose = () => {
        setBackdropopen(false);
      };

  return (
    <React.Fragment>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
        onClick={BackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  )
}

export default Spinner;