import React,{useEffect,useState} from 'react';
import Header from './OpenHeader';
import Footer from './Footer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import axios from 'axios';
import Spinner from './Spinner';
import Snackbar from '@mui/material/Snackbar';
import {useNavigate} from 'react-router-dom';
import {domain} from '../domain';
const defaultTheme = createTheme();
axios.defaults.withCredentials = true;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Home() {

    const navigate = useNavigate()

    const [data,setdata] = useState([{
        "Item_Name":"",
        "Item_ID":"",
        "Item_Price":"",
        "Item_Shipping_Tax":"",
        "Item_Location":"",
    }]);

    const [Backdropopen, setBackdropopen] = useState(true);
    const [auth, setauth] = useState(false);
    const [state, setState] = useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
      message:""
    });
    const { vertical, horizontal, open, message } = state;
  
    const handleClose = () => {
      setState({ ...state, open: false, message:"" });
    };

    const [Page, setPage] = useState(1);

    const next = (Page) => {
      setBackdropopen(true)
      axios.get(`${domain}api/getorders?page=${Page+1}`).then((data)=>{
        let arr = data.data.Orders
        setdata(arr); 
        // fetchPurchasePrices(data.data.Orders);
        setPage(Page+1)
        setBackdropopen(false)
      }).catch((err)=>{
        setBackdropopen(false)
        setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
      });
    }
    const pre = (Page) => {
      if(Page !==1){
        setBackdropopen(true)
        axios.get(`${domain}api/getorders?page=${Page-1}`).then((data)=>{
          let arr = data.data.Orders
          setdata(arr); 
        //   fetchPurchasePrices(data.data.Orders);
          setPage(Page-1)
          setBackdropopen(false)
        }).catch((err)=>{
          setBackdropopen(false)
          setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
        });
      }
    }

//     const fetchPurchasePrices = async (Data) => {
//       const updatedData = await Promise.all(Data.map(async (item) => {
//           if (item.TransactionArray.Transaction.Item.ItemID) {
//               try {
//                   const response = await axios.get(`${domain}api/purchaseprice?ID=${item.TransactionArray.Transaction.Item.ItemID}`);
//                   item.Purchase_Price = response.data.Item_Price;
//                   item.Item_URL = response.data.Item_URL;
//               } catch (error) {
//                   console.error('Error fetching purchase price:', error);
//               }
//           }
//           return item;
//       }));

//       setdata(updatedData);
//   };

    useEffect(()=>{
      setBackdropopen(true)
      axios.get(`${domain}api/check`).then((data)=>{
      setauth(data.data.Auth)
        if (!data.data.Auth){
          setBackdropopen(false)
            navigate("/login")
        }
        else{
          axios.get(`${domain}api/sold`).then((data)=>{
            setBackdropopen(false)
            let arr = data.data.All
            setdata(arr)
            
        //   fetchPurchasePrices(data.data.Orders);

        });
        }
    }).catch((err)=>{
      setBackdropopen(false)
      setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    });
    },[])

  return (
    <ThemeProvider theme={defaultTheme}>
        <Header/>
        <Spinner open={Backdropopen} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={message}
          key={vertical + horizontal}
        />
        {auth ?
        <Container sx={{mb:12,mt:2}} style={{position:"relative"}} fixed>
            <TableContainer component={Paper} style={{height:"700px",overflowY:"scroll"}}>
                <Table id="table" sx={{ minWidth: 100 }} aria-label="customized table" stickyHeader>
                <TableHead>
                    <TableRow>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Sr#</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Order_ID</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Item_Name</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Item_ID</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Purchase_Price</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Item_Extra_Shipping</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Item_Location</StyledTableCell>
                    <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">Date Sold</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((element,index)=>(
                        
                    <StyledTableRow key={index}>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} component="th" scope="row">
                            {index+1}
                        </StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element['Order_ID']}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element['Item_Name']}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element['Item_ID']}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element["Item_Price"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element["Item_Shipping_Tax"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element["Item_Location"]}</StyledTableCell>
                        <StyledTableCell style={{whiteSpace:"nowrap"}} align="center">{element["Date"]}</StyledTableCell>
                    </StyledTableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            <Box
            style={{marginTop:"1%"}}
              sx={{
                display: 'flex',
                flexDirection: 'rows',
                alignItems: 'center',
              }}
            >
            <Button disabled={Page===1?true:false} onClick={()=>{pre(Page)}} style={{padding:"15px 30px",marginBottom:"2%"}} variant="contained" color="primary">Previous</Button>
            <Button disabled={data.length<100?true:false} onClick={()=>{next(Page)}} style={{padding:"15px 50px",marginBottom:"2%",marginLeft:"1%"}} variant="contained" color="primary">Next</Button>
            </Box>
        </Container>:<></>}
        <Footer/>
    </ThemeProvider>
  )
}

export default Home;