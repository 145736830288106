import React,{useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from './OpenHeader';
import Footer from './Footer';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Spinner from './Spinner';
import {domain} from '../domain';
const defaultTheme = createTheme();
axios.defaults.withCredentials = true;
export default function SignIn() {

  const navigate = useNavigate()

  const [Backdropopen, setBackdropopen] = useState(false);

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message:""
  });
  const { vertical, horizontal, open, message } = state;

  const handleClose = () => {
    setState({ ...state, open: false, message:"" });
  };

  const [Auth, setAuth] = useState(false);


  useEffect(()=>{
    setBackdropopen(true)
    axios.get(`${domain}api/check`).then((data)=>{
      if (!data.data.Auth){
        setBackdropopen(false)
        navigate("/login")
    }
    else{
      setBackdropopen(false)
      setAuth(true)
      navigate("/orders")
    }
  }).catch(()=>{
    setBackdropopen(false)
    setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    })
  },[])

  const handleSubmit = (event) => {
    setBackdropopen(true)
    event.preventDefault();
    let data = new FormData(event.currentTarget);

    axios.post(`${domain}api/getorders`,{"username":data.get('username'),"password":data.get('password')}).then((data)=>{
        if (data.data.Auth){
          navigate("/orders")
          setBackdropopen(false)
        }
        else{
          setBackdropopen(false)
          setState({ vertical: 'top',horizontal: 'center', open: true,message:"Wrong Credentials" });
        }
    }).catch(()=>{
      setBackdropopen(false)
      setState({ vertical: 'top',horizontal: 'center', open: true,message:"Something Went Wrong!" });
    })

  };

  return (
    <ThemeProvider theme={defaultTheme}>
        <Header/>
        <Spinner open={Backdropopen} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={message}
          key={vertical + horizontal}
        />
      <Container component="main" maxWidth="xs">
        {!Auth?<>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
          <Box style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          </Box>
          <Typography style={{display:"flex",flexDirection:"column",alignItems:"center"}} component="h1" variant="h5">
            Log in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email or Username"
              name="username"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
          </Box>
          </div>
        </Box>
        </>:<></>}
      </Container>
    </ThemeProvider>
  );
}